import {
  Autocomplete,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import PublicGoogleSheetsParser from "public-google-sheets-parser";
import React, { useEffect, useState } from "react";
import {
  CountryItem,
  CountryItemMessage,
  mapMessages,
} from "../utils/mapping.helper";

interface CountryPickerProps {}
const apiKey = process.env.REACT_APP_API_KEY;

const CountryPicker: React.FC<CountryPickerProps> = () => {
  const [countries, setCountries] = useState<CountryItem[]>([]);
  const [country, setCountry] = useState<CountryItem>();

  useEffect(() => {
    const spreadsheetId = apiKey;
    const parser = new PublicGoogleSheetsParser(spreadsheetId);
    parser.parse().then((items: CountryItemMessage[]) => {
      const mapped = mapMessages(items);

      setCountries(mapped);
    });
  }, []);

  const handleSelect = (
    event: React.ChangeEvent<{}>,
    value: CountryItem | null
  ) => {
    if (value) {
      setCountry(value);
    }
  };

  return (
    <Grid container rowSpacing={5}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        {countries && (
          <Autocomplete
            id="combo-box-demo"
            options={countries}
            getOptionLabel={(option) =>
              `${option.Kraj} - ${option.Czynnik} - ${option.Okres} - ${option.Obszar}`
            }
            groupBy={(option) => option.Kraj}
            renderInput={(params) => <TextField {...params} label="Kraj" />}
            onChange={handleSelect}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {country && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Kraj</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Czynnik zakaźny</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Okres karencji</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Obszar występowania</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={country?.Kraj}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {country?.Kraj}
                  </TableCell>
                  <TableCell align="right">{country?.Czynnik}</TableCell>
                  <TableCell align="right">{country?.Okres}</TableCell>
                  <TableCell align="right">{country?.Obszar ?? "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default CountryPicker;
