import { Container } from "@mui/material";
import "./App.css";
import CountryPicker from "./components/CountryPicker";

function App() {
  return (
    <div className="App">
      <Container>
        <CountryPicker />
      </Container>
    </div>
  );
}

export default App;
