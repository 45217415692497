export interface CountryItemMessage {
  Kraj: string;
  "Czynnik zakaźny"?: string;
  "obszar występowania"?: string;
  "okres karencji"?: string;
}

export interface CountryItem {
  Kraj: string;
  Czynnik?: string;
  Okres?: string;
  Obszar?: string;
}

export const mapMessages = (items: CountryItemMessage[]): CountryItem[] => {
  let currentCountry: CountryItemMessage;
  const mapped: CountryItem[] = items.map((i) => {
    const model: CountryItem = {
      Kraj: i.Kraj ?? currentCountry.Kraj,
      Czynnik: i["Czynnik zakaźny"] ?? currentCountry["Czynnik zakaźny"],
      Obszar: i["obszar występowania"] ?? currentCountry["obszar występowania"],
      Okres: i["okres karencji"] ?? currentCountry["okres karencji"],
    };
    if (i.Kraj) {
      currentCountry = i;
    }
    return model;
  });

  return mapped;
};
